import React from 'react';

function Terms() {

  return (
    <>
    <div className="Content-center" style={{textAlign: 'left'}}>
    <h1>Terms and conditions</h1>
<h2>General</h2>
<p>1a. These Conditions of Trading (“Conditions”) shall be incorporated 
	into all contracts for the supply of goods (a“Contract”) made 
	between Admor Limited (“the Company”) trading as Wizziprint and any person or persons 
	contracting with the Company and named as the buyer in the Company’s 
	standard notification of acceptance of an order (“the Buyer”).</p>
<p>1b. The headings in these Conditions do not affect or limit 
	their meaning or construction.</p>
<p>1c. No variation of these conditions shall be valid unless 
	previously agreed in writing by the Company.</p>
<p>1d. All descriptions, illustrations, designs or indications 
	of performance given in any catalogue, price list or advertising 
	matter or other document are intended to give the Buyer a general 
	idea of goods to be supplied and are not intended to be, and 
	are not, of any contractual effect and do not make any Contract 
	a sale by description. Notwithstanding that a sample of goods 
	may have been exhibited to and inspected by the Buyer it is 
	hereby declared that this shall not constitute a sale by sample 
	under any Contract. </p>
<h2>Price Variation</h2>
<p>2. Estimates are based on the Company’s current costs of 
	production and, unless otherwise agreed, are subject to amendment 
	on or at any time after acceptance to meet any rise or fall 
	in such costs.</p>
<h2>Tax</h2>
<p>3. Except in the case of a customer who is not contracting 
	in the course of a business not holding himself out as doing 
	so, the Company reserves the right to charge the amount of any 
	value added tax payable whether or not included on the estimate 
	or invoice.</p>
<h2>Preliminary work</h2>
<p>4. All work carried out, whether experimentally or otherwise, 
	at customer’s request shall be charged.</p>
<h2>Copy</h2>
<p>5. A charge may be made to cover any additional work involved 
	where copy supplied is not clear and legible.</p>
<h2>Proofs</h2>
<p>6. Where proofs of work are supplied to the customer for 
	approval, the Company shall incur no liability for any errors 
	which are not detected and clearly marked by the customer for 
	correction on those proofs, in cases where matters including 
	style, type, or layout are left by the customer to the discretion 
	of the Company, any subsequent change in specification including 
	authors amendments made by the customer will be charged.</p>
<h2>Payment Terms</h2>
<p>7a. Normal payment terms are net cash 21 days from date of 
	delivery and time shall be of the essence in this respect, but 
	the Company reserves the right to modify these terms or to impose 
	C.O.D. terms.</p>
<p>7b. No discounts or other terms of payment will apply unless 
	confirmed in writing by the Company.</p>
<p>7c. If payment is not received in full when due, the Company 
	reserves the right to charge the Buyer interest on the unpaid 
	amount at a rate of two per cent (2%) per month or at such other 
	rate as the Company may specify.</p>
<h2>Delivery</h2>
<p>8a. Every endeavour will be made by the Company to adhere 
	to delivery dates quoted but such dates are estimates only and 
	are not guaranteed, neither shall they under any circumstances 
	be deemed to be a term or condition of any Contract between 
	the Buyer and the Company. The Company shall accept no liability 
	of whatsoever nature for failure to meet such dates and such 
	failure shall not entitle the Buyer to repudiate or cancel such 
	Contract unless an express guarantee to effect delivery by a 
	specified date has been given in writing by a director of the 
	Company.</p>
<p>8b. The Company reserves the right to suspend delivery in 
	respect of any orders.</p>
<h2>Damage on Delivery and Non-delivery</h2>
<p>9a. Goods shall be deemed to have been delivered complete, 
	undamaged, in good condition and to the Buyer’s satisfaction 
	unless the Company receives written notice to the contract within 
	(5) working days (10 working days in respect of overseas sales) 
	from the date of delivery. The Company’s liability for partial 
	loss or damage or non-delivery of goods shall not exceed the 
	amount of the contract price of such goods.</p>
<p>9b. If the Buyer fails or refuses to take delivery of the 
	goods he shall be liable to the Company for any loss, whether 
	consequential or otherwise, occasioned by such or refusal and 
	or any charge, cost or expense thereby incurred by the Company 
	(including carriage and storage costs) and the Company reserves 
	the right to impose a reasonable charge for the care and custody 
	of goods.</p>
<h2>Claims</h2>
<p>10. Advice of damage, delay or partial loss of goods in transit 
	or of non-delivery must be given in writing to the Company and 
	the carrier within three clear days of delivery (or in the case 
	of non-delivery, within 28 days of despatch of the goods) and 
	any claim in respect thereof must be made in writing to the 
	Company and the carrier within seven clear days of delivery 
	(or, in the case of non-delivery, within 42 days of despatch). 
	All other claims must be made in writing to the company within 
	27 days of delivery. The company shall not be liable in respect 
	of any claims unless the aforementioned requirements have been 
	complied with except in any particular case where the customer 
	proves that (i) it was not possible to comply with the requirements 
	and (ii) advice (where required) was given and the claim made 
	as soon as reasonably possible.</p>
<h2>Limitation of the Company’s Liability</h2>
<p>11a. Except as otherwise expressly mentioned in these Conditions, 
	the Company shall have no liability of any kind to the Buyer 
	in respect of any loss or damage (whether direct, indirect or 
	consequential) suffered by the Buyer, whether in contract or 
	negligence or otherwise howsoever, other than for death or personal 
	injury to the extent that it is caused by the negligence of 
	the Company, it’s employees or agents.</p>
<p>11b. No forbearance or indulgence by the Company whether 
	in respect of these conditions or otherwise shall in any way 
	affect or prejudice the rights of the Company against the Buyer 
	or be taken as a waiver of any of these Conditions.</p>
<h2>Standing material</h2>
<p>12a. Metal, magnetic and optical media, film. bromide and 
	other materials owned by the Company and used by him in the 
	production of type, plates, dies, film-output, negatives, positives 
	and the like shall remain the exclusive property of the Company. 
	Such items when supplied by the customer shall remain the customer’s 
	property.</p>
<p>12b. Type may be distributed and lithographic, film and plates, 
	magnetic and optical media or other work effaced immediately 
	after the order is executed unless written arrangements are 
	made to the contrary, in the latter event, rent may be charged.</p>
<h2>Customer’s property</h2>
<p>13a. Except in the case of a customer who is not contracting 
	in the course of a business nor holding himself out as doing 
	so, customer’s property and all property supplied to the Company 
	by or on behalf of the customer shall, while it is in the possession 
	of the company or in transit to or from the customer, be deemed 
	to be at customer’s risk unless otherwise agreed and the customer 
	should insure accordingly.</p>
<p>13b. The Company shall be entitled to make a reasonable charge 
	for the storage of any customer’s property left with the Company 
	before receipt of the order or after notification to the customer 
	of completion of the work.</p>
<h2>Material supplied by the customer</h2>
<p>14a. The Company may reject any paper, magnetic media, film, 
	plates or other materials supplied or specified by the customer 
	which appear to him to be unsuitable. Additional cost incurred 
	if materials are found to be unsuitable during production may 
	be charged except that if the whole or any part of such additional 
	cost could have been avoided but for unreasonable delay by the 
	Company in ascertaining the unsuitability of the materials then 
	that amount shall not be charged to the customer.</p>
<p>14b. Where materials are so supplied or specified, the company 
	will take every care to secure the best, but responsibility 
	will not be accepted for imperfect work caused by defects in 
	or unsuitability of materials so supplied or specified.</p>
<p>14c. Quantities of materials supplied shall be adequate to 
	cover normal spoilage.</p>
<h2>Insolvency</h2>
<p>15. If the customer ceases to pay his debts in the ordinary 
	course of business or cannot pay his debts as they become due 
	or being a company is deemed to be unable to pay his debts or 
	has a winding up petition against it or being a person commits 
	an act of bankruptcy or has a bankruptcy petition issued against 
	him, the company without prejudice to other remedies shall; 
	i) have the right to proceed further with the contract or any 
	other work for the customer and be entitled to charge for work 
	already carried out (whether completed or not) and materials 
	purchased for the customer, such charge to be an immediate debt 
	due to him, and ii) in respect of all unpaid debts due from 
	the customer have a general lien on all goods and property in 
	his possession (whether worked on or not) and shall be entitled 
	on the expiration of 14 days’ notice to dispose of such goods 
	or property in such manner and at such price as the Company 
	thinks fit and to apply the proceeds towards such debts.</p>
<h2>Illegal matter</h2>
<p>16a. The Company shall not be required to print any matter 
	which in his opinion is or may be of an illegal or libellous 
	nature or an infringement of the proprietary or other rights 
	of any third party.</p>
<p>16b. The Company shall be indemnified by the customer in 
	respect of any claims, costs and expenses arising out of any 
	libellous matter or any infringement of copyright, patent, design 
	or of any other proprietary or personal rights contained in 
	any material printed for the customer. The indemnity shall extend 
	to any amounts paid on a lawyer’s advicein settlement of any 
	claim.</p>
<h2>Force majeure</h2>
<p>17. Force majeure The Company shall be under no liability 
	if he shall be unable to carry out any provision of the contract 
	for any reason beyond his control including (without limiting 
	the foregoing) Act of God, legislation, war, fire, flood, drought, 
	failure of power supply, lock-out, strike or other action taken 
	by employees in contemplation of furtherance of a dispute or 
	owing to any inability to procure materials required for the 
	performance of the contract. During the continuance of such 
	a contingency the customer may be written notice to the Company 
	elect to terminate the contract and pay for work done and materials 
	used, but subject thereto shall otherwise accept delivery when 
	available.</p>
<h2>Title</h2>
<p>18a. The risk in the goods passes to the customer upon delivery.</p>
<p>18b. Until full payment has been received by the company 
	for all amounts due to the Company from the customer under this 
	or any other contract. Property in the goods shall remain in 
	the Company.</p>
<p>18c. Until payment (under sub-clause b herein) the customer 
	is required to store the goods in such a way that they are clearly 
	the property of the Company.</p>
<p>18d. The above provisions shall not be prejudiced by work 
	upon the goods by the Company or merger of the goods with other 
	goods or materials insofar as the goods remain reasonably identifiable 
	and retrievable.</p>
<p>18e. If during such time as title has not passed in the goods 
	the goods are worked upon or merged with other goods or materials 
	so that they are no longer reasonably identifiable and retrievable 
	the property in the resultant product shall be and remain with 
	the Company until payment.</p>
<p>18f. The customer must insure the goods in whatever form 
	against all risks while they are held at the customer’s risk.</p>
<p>18g. In the event of loss and/or damage to the goods or any 
	other event reducing their immediate value which has been insured 
	against under sub-clause f while at the customer’s risk the 
	customer must promptly secure payment under the policy or policies 
	relating to the goods and must account to the company for the 
	same or, if the Company so desires, assign to the company the 
	benefit of such policy in respect of the goods.</p>
<p>18h. Until payment the customer shall keep the goods for 
	the Company in its capacity as fiduciary owner.</p>
<p>18i. If payment becomes overdue in whole or in part the Company 
	may recover or resell the goods and may enter the customer’s 
	premises for this purpose.</p>
<p>18j. Notwithstanding that title to the goods has not passed 
	to the customer the customer may sell or resultant product as 
	agent for the company and in the normal course of the customer’s 
	business provided always that if the customer receives payment 
	upon such sale in whole or in part the customer shall account 
	to the company for such payment to the extent of indebtedness 
	under sub-clause b.</p>
<p>18k. If under sub-clause j the customer receives monies and 
	these are held by the customer pending payment to the company 
	these monies shall be held in a separate account.</p>
<p>18l. If under sub-clause j the customer sells but does not 
	receive full payment the customer shall in the event of payment 
	to the company being or becoming overdue assign to the Company 
	the claim or claims he has in respect of outstanding payment.</p>
<p>18m In the event of sale under sub-clause j the customer 
	must notify the Company of the material particulars of the sale 
	promptly and in any event within five days of the relevant agreement 
	to sell.</p>
<h2>Law</h2>
<p>19. These conditions and all other express terms of the contract 
	shall be governed and construed in accordance with the laws 
	of England. </p>
    </div>

    </>
  );
}

export default Terms;
