import React from 'react';
import Lightbox from "react-lightbox-component";

import "./lightbox.css";

const images = [
    {
        title: 'SHORELINE BROCHURE',
        description: '"Wizziprint Digital have been printing our brochures for some time now, they are always of a high standard and delivered fast"',
        src: '/assets/Testimonials/Shoreline.jpg',
    },
    {
        title: 'KIRKHEATON PODIATRY BUSINESS CARDS',
        description: '"What a fantastic service I\'ve received from you. From first contact, to proof, to quality product within a week. A very happy customer."',
        src: '/assets/Testimonials/Kirkheaton.jpg',
    },
    {
        title: 'ARUN VIEW MENUS',
        description: '"Wizziprint Digital provided an excellent, fast and friendly service as usual"',
        src: '/assets/Testimonials/Arun_View.jpg',
    },
    {
        title: 'HEATHERSTEVENSART.CO.UK',
        description: '"The new cards have just arrived and they look amazing. Thank you so much."',
        src: '/assets/Testimonials/Heather_Stevens.jpg',
    },
    {
        title: 'ANGMERING PC BMX EVENT POSTERS',
        description: '"Great design, Fast delivery, Lovely people to work with."',
        src: '/assets/Testimonials/Angmering_Dogs_Show.jpg',
    },
    {
        title: 'LHRS STATIONARY',
        description: '"We have been using Wizziprint Digital for over 7 years and have always found their service to be outstanding. I would recommend them to anyone"',
        src: '/assets/Testimonials/LRHS_Stationary.jpg',
    },
    {
        title: 'WINEWARE BUSINESS CARDS',
        description: '"It’s a pleasure to do business with Wizziprint Digital. Quality printing and prompt delivery."',
        src: '/assets/Testimonials/Wineware_Business_Cards.jpg',
    },
    {
        title: 'SBTF NEWSLETTERS',
        description: '"Wizziprint Digital print and mail out our monthly newsletter. Great service at a competitive price."',
        src: '/assets/Testimonials/SBTF.jpg',
    },
    {
        title: 'LIMINGS MENUS',
        description: '"Wizziprint Digital have always given us a great service. Always helpful and great quality for all our prints. That\'s why we continue to choose their service."',
        src: '/assets/Testimonials/Limings_Menu.jpg',
    },
    {
        title: 'POWERBRONZE EXHIBITION LEAFLETS',
        description: '"Wizziprint Digital have produced our exhibition leaflets for many years now and are always of a high standard and delivered fast"',
        src: '/assets/Testimonials/PowerBronze.jpg',
    }
];


function Testimonials() {

  return (
        <div className="Content-center" style={{width:'90%', maxWidth: 'none'}}>
            <p className="Header-big" style={{paddingTop: '100px'}}>
                Why work with us?
            </p>
            <p className="Header-sub" style={{paddingBottom: '40px'}}>
                This is what our customers have to say about the service we provide.
            </p>
            <Lightbox
                images={images}
                thumbnailWidth='300px'
                thumbnailHeight='300px'
                showImageModifiers={false}
            />
        </div>

  );
}

export default Testimonials;
