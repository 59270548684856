import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import StickyHeader from './header.js';
import {VerticleButton as ScrollUpButton} from "react-scroll-up-button";
import './App.css';

import Home from './pages/home';
import Testimonials from './pages/testimonials';
import GetInTouch from './pages/get-in-touch';
import Terms from './pages/terms';

import withTracker from './withTracker';

import ReactGA from 'react-ga';

// based on https://leaston0.wixsite.com/mysite
// wizziprint.dentalplus.online: wizziftp / dUa7_99g
// wizziprint.co.uk: wizziprint / O459c^uk

function App() {

    ReactGA.initialize('UA-19040574-6');
    ReactGA.pageview(window.location.pathname + window.location.search);

    return (    
        <div className="App">
            <ScrollUpButton />
            <Router>
                <StickyHeader />

                <div className="App-content">
                    <Switch>
                        <Route exact path="/" component={withTracker(Home)} />
                        <Route path="/testimonials" component={withTracker(Testimonials)} />
                        <Route path="/get-in-touch" component={withTracker(GetInTouch)} />
                        <Route path="/terms" component={withTracker(Terms)} />
                        <Route path="*" component={withTracker(Home)} />
                    </Switch>


                </div>

                <footer className="App-footer">
                    <div className="Content-center">
                        <Grid container spacing={2}>
                            <Grid item xs={6}> 
                                © WizziPrint {(new Date().getFullYear())}<br/>
                                <Link to="/terms">Terms and Conditions</Link>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}> 
                                Wizziprint<br/>
                                Artex Avenue<br/>
                                Phoenix Parade<br/>
                                Rustington<br/>
                                BN16 3LN<br/>
                                01903 858910<br/>
                                sales@wizziprint.co.uk
                            </Grid>
                        </Grid>
                    </div>
                </footer>   
                
            </Router>    

                     
        </div>

    );
}

export default App;
