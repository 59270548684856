import React, { useRef } from 'react';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Slider from "react-slick";

import PrintingIcon from '../assets/Home/Printing.png';
import FinishingIcon from '../assets/Home/Finishing.png';
import ArtworkingIcon from '../assets/Home/Artworking.png';
import PrintMataterial from '../assets/Home/Printed Materials.png';

import HalfFold from '../assets/Home/Half Fold.png';
import LetterFold from '../assets/Home/Letter Fold.png';
import Concertina from '../assets/Home/Concertina.png';
import GateFold from '../assets/Home/Gate Fold.png';
import DoubleParallel from '../assets/Home/Double Parallel.png';

import ScrollDentalPlus from '../assets/Home/DentalPlus.jpg';
import ScrollEastPreston from '../assets/Home/East Preston.jpg';
import ScrollLRHS from '../assets/Home/LRHS Stationary.jpg';


function HomePage() {
    var scrollSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 3000,
        autoplay: true
    };

    const poRef = useRef(null);
    const foRef = useRef(null);
    const aRef = useRef(null);

    const scrollClick = (refObject) => {
        refObject.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

  return (
    <>
    <div className="Content-center">
        
        <p className="Header-big" style={{paddingTop: '100px'}}>
            Welcome to Wizziprint Digital
        </p>
        <p className="Header-sub">
            Tailor-made stationary solutions suited to your company or business
        </p>
        <p>
            Wizziprint Digital offers the full range of commercial design and print solutions customised to build your business.
        </p>
        <p>
            With excellent industry knowledge, creative ideas and experience, we match the highest levels of quality with
            great prices - a winning combination.
        </p>
        <p style={{fontWeight: 'bold', letterSpacing: '-0.05em'}}>
            So, why not give us a go?  We think you'll be impressed...
        </p>
        <p>
            We know we can offer you the best in quality, service, expertise, creativity, friendliness, efficiency,
            pricing and much more... With the latest digital printing technology choose
            Wizziprint Digital for all your printing needs.
        </p>
        <p style={{paddingTop: '30px'}}>
            <Link to="/get-in-touch">
                <Button variant="contained" className="Quote-button">GET A QUOTE</Button>
            </Link>
        </p>
    </div>
    <div className="Print-options">
        <div className="Content-center" style={{maxWidth: '900px'}}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4} onClick={() => scrollClick(poRef)}> 
                    <img src={PrintingIcon} height="135px" alt=""></img>
                    <p className="Option-header">PRINTING OPTIONS</p>
                    <p className="Option-text">
                        Choose from a range of different media to make your print perfect.<br/>
                        Click here to view our products.
                    </p>
                </Grid>
                <Grid item xs={12} sm={4} onClick={() => scrollClick(foRef)}> 
                    <img src={FinishingIcon} height="135px" alt=""></img>
                    <p className="Option-header">FINISHING OPTIONS</p>
                    <p className="Option-text">
                        Choose from a range of finishes and packaging options.<br/>
                        Click here to view what we offer.
                    </p>
                </Grid>
                <Grid item xs={12} sm={4} onClick={() => scrollClick(aRef)}> 
                    <img src={ArtworkingIcon} height="135px" alt=""></img>
                    <p className="Option-header">ARTWORKING</p>
                    <p className="Option-text">
                        We offer an artworking service to create the ideal design for your product.<br/>
                        Click here for more information
                    </p>
                </Grid>
            </Grid>
        </div>
    </div>
    <div className="Band-div-light">
        <div className="Content-center">
            <p className="Header-big" ref={poRef} style={{paddingTop: '30px'}}>
                Printing Options
            </p>
            <p className="Header-sub">
                Digital printing solutions galore!
            </p>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}> 
                    <img src={PrintMataterial} width="100%" alt=""></img>
                </Grid>
                <Grid item xs={12} sm={6} className="Content-left"> 
                    <p style={{fontWeight: 'bold'}}>What are my options?</p>
                    <p>With state of the art dry toner technology allows us to give you a high quality 
                        reproduction on a full range of media from 60gsm up to 400gsm, coated or uncoated stocks.</p>
                    <p>Our prices are competitive and we are dedicated to providing the very best quality 
                        and service for you. Your order being delivered faster than you might have thought 
                        possible. Our couriers collect daily from us and despatch country wide on a 24hour 
                        delivery service.</p>
                    <p>Each job is costed individually to be sure you get the best possible price, 
                        <Link to="/get-in-touch">click here for a quote</Link>.</p>
                    <p>Listed below are some of the more popular products produced in house:</p>
                </Grid>
                <Grid item xs={12}>
                Mailers, Brochures, Menus, Leaflets, Booklets, Banners, Compliment Slips
                Business Cards, Letterheads, Posters, Flyers, Manuals, Postcards, Invitations, 
                Newsletters, Business forms and more.
                </Grid>
            </Grid>
        </div>
    </div>
    <div className="Band-div-dark">
        <div className="Content-center">
            <p className="Header-big" ref={foRef}>
                Finishing Options
            </p>
            <p className="Header-sub">
            Multiple in-house finishing and packaging options for your printing
            </p>
            <Grid container spacing={2}>
                <Grid className="Content-left" item xs={12} sm={6}> 
                <p><span style={{fontWeight: 'bold', textDecoration: 'underline'}}>Finishing Options</span></p>
                <p><span style={{fontWeight: 'bold'}}>Creasing and Perforating Machine: </span> 
                Gives a precision crease for Presentation Folders, Greetings Cards, folded Business Cards etc. 
                Also used for perforating for tear-off documents. For example: Check Books, Raffle Tickets etc.</p>
                <p><span style={{fontWeight: 'bold'}}>Drilling Machine:</span> 
                Drill holes in your printing to fit any ring binder mechanism from two to seven holes. 
                Also suitable for luggage and parcel tags. </p>
                <p><span style={{fontWeight: 'bold'}}>Collating Machine: </span> 
                Quickly collates multiple NCR sets using four stations for a speedy turn round. 
                Will also give an accurate sheet count prior to padding.</p>
                <p><span style={{fontWeight: 'bold'}}>Polar 78 X Plus Guillotine: </span> 
                Fully programmable precision guillotine gives an accurate and clean finish to all your print work.</p>
                <p><span style={{fontWeight: 'bold'}}>Stab Stitcher: </span> 
                Used for thicker stapled pads with backboards and covers. For example; Cheque Books, Invoice or Order Books.</p>
                <p><span style={{fontWeight: 'bold'}}>Folding Machine: </span> 
                Our stream or pulse feed folder is quick and accurate for precision folding to compliment the 
                quality print we produce: Folds from A3 down to A6.</p>
                </Grid>
                <Grid className="Content-left" item xs={12} sm={6}> 
                <p><span style={{fontWeight: 'bold'}}>Padding Presses: </span>
                To produce quality multiple pads from A6 to A3.</p>
                <p><span style={{fontWeight: 'bold'}}>Booklet Maker: </span>
                Multipage documents with stapled spine from A6 to A4. For example; Newsletters, Magazines, and Brochures.</p>
                <p><span style={{fontWeight: 'bold'}}>Numbering Machine: </span>
                Rotary numbering machine for Raffle Tickets, Receipt Books, Invoices etc. Up to 4 numbers to a sheet.</p>
                <p><span style={{fontWeight: 'bold', textDecoration: 'underline'}}>Packaging Options</span></p>
                <p><span style={{fontWeight: 'bold'}}>Shrink Wrapping: </span>
                We offer a full shrink wrapping service to preserve the quality of your printing. Please ask for details.</p>
                <p><span style={{fontWeight: 'bold'}}>Heat Banding: </span>
                All our work is heat banded in plastic or paper to minimise any possible damage in transit.</p>
                <p><span style={{fontWeight: 'bold'}}>Boxed for Despatch: </span>
                Our despatch department ensure that all packages are secure and double boxed. Our carriers collect daily 
                and all work is shipped on a 24 hour service to any mainland UK address.</p>
                </Grid>
                <Grid className="Content-left" item xs={12}>
                    <p>See examples below:</p>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid className="Content-center" item xs={6} sm={2}>
                    <img src={HalfFold} height="150px" alt=""></img>
                        <p>Half Fold 4 Pages</p>
                </Grid>
                <Grid className="Content-center" item xs={6} sm={2}>
                    <img src={LetterFold} height="150px" alt=""></img>
                        <p>Letter Fold 6 Pages</p>
                </Grid>
                <Grid className="Content-center" item xs={6} sm={2}>
                    <img src={Concertina} height="150px" alt=""></img>
                        <p>Concertina 6 Pages</p>
                </Grid>
                <Grid className="Content-center" item xs={6} sm={3}>
                    <img src={GateFold} height="150px" alt=""></img>
                        <p>Gate Fold 6 Pages</p>
                </Grid>
                <Grid className="Content-center" item xs={6} sm={2}>
                    <img src={DoubleParallel} height="150px" alt=""></img>
                        <p>Double Parallel Fold 8 Pages</p>
                </Grid>

            </Grid>
        </div>
    </div>
    <div className="Band-div-light">
        <div className="Content-center">
            <p className="Header-big" ref={aRef}>
                Artworking
            </p>
            <p className="Header-sub">
                Creating artwork to make your print perfect
            </p>
            <p>
                Wizziprint Digital can offer bespoke artwork and design to suit your business or printing endeavour. 
                With a team of creatives fully certified in the Adobe Creative Suite we can bring your vision to life. 
                See below for just some examples of our previous artworking or
                visit our testimonials page by <Link to="/testimonials">clicking here</Link>
            </p>
            <p style={{fontWeight: 'bold', letterSpacing: '-0.05em'}}>
                Got your own artwork?  No problem!
            </p>
            <p>
                Send us your PDFs and we will preflight them and do any last minute checks to
                ensure your print is perfect! We can accept your artwork in most formats. Microsoft word and publisher
                documents may incurr a small surcharge for conversion.
            </p>

        </div>
    </div>
    <div className="Content-center">
        <div className="container">
        <Slider {...scrollSettings}>
          <div>
            <img className="Scroll-image" src={ScrollDentalPlus} alt=""/>
            <div className="Scroll-text">
                DentalPlus Mailer<br/>
                Promotional mailer designed to spread the word
            </div>
          </div>
          <div>
            <img className="Scroll-image" src={ScrollEastPreston} height="450px" alt=""/>
            <div className="Scroll-text">
                East Preston Programme<br/>
                Programme designed for a local festival
            </div>
          </div>
          <div>
            <img className="Scroll-image" src={ScrollLRHS} height="450px" alt=""/>
            <div className="Scroll-text">
                LRHS Stationary<br/>
                Letterheads, business cards and comp slips
            </div>
          </div>
        </Slider>
      </div>

    </div>

    </>
  );
}

export default HomePage;
