import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Form, Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui';

function GetInTouch() {

    const [formState, setFormState] = useState(0);

    const Error = ({ name }) => (
        <Field
          name={name}
          subscribe={{ touched: true, error: true }}
          render={({ meta: { touched, error } }) =>
            touched && error ? <span>{error}</span> : null
          }
        />
    )
      

    async function postData(url = '', data = {}) {
        // Default options are marked with *
        const response = await fetch(url, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Access-Control-Allow-Origin':'*',
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *client
          body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
        return await response; // parses JSON response into native JavaScript objects
    }

    const onSubmit = async values => {

        values.access = "whizziprint email sending";

        postData('email.php', { values })
        .then((data) => {
            console.log(data); // JSON data parsed by `response.json()` call
            if (data.statusText === "OK") {
                // hide the form
                // show the response text
                setFormState(1);
            }
            else
            {
                // show the error text and email address
                setFormState(2);
            }
        });

    }

    return (
        <div className="Content-center" style={{paddingBottom: '100px'}}>
            <p className="Header-big" style={{paddingTop: '100px'}}>
                Get in Touch
            </p>
            <p className="Header-sub" style={{paddingBottom: '40px'}}>
                Open 9am - 5pm, Monday to Friday
            </p>
            {formState === 0 && (
                <Form
                onSubmit={onSubmit}
                render={({ handleSubmit, form, submitting, pristine, values }) => 
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} style={{textAlign: 'left'}}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        fullWidth
                                        name="name"
                                        required
                                        component={TextField}
                                        type="text"
                                        label="Name"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        fullWidth
                                        name="email"
                                        component={TextField}
                                        required
                                        type="email"
                                        label="Email"
                                    />
                                    <Error name="email" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        name="subject"
                                        component={TextField}
                                        type="text"
                                        label="Subject"
                                    />

                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        name="phone"
                                        component={TextField}
                                        type="text"
                                        label="Phone Number"
                                    />

                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        name="address"
                                        multiline
                                        rows="4"                                    
                                        component={TextField}
                                        type="text"
                                        label="Address"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        name="message"
                                        required
                                        multiline
                                        rows="6"
                                        component={TextField}
                                        type="text"
                                        label="Message"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button fullWidth variant="contained" color="primary" className="Send-button"
                                        type="submit" disabled={submitting || pristine}>SEND</Button >
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>    
                </form>

                }
                /> 
            )}
            {formState === 1 && (
                <div>Thank you for contact us, we'll get back to you ASAP</div>
            )}
            {formState === 2 && (
                <div>Oops, there was an error sending the form.<br/>Please email <a href="email:sales@wizziprint.co.uk">sales@wizziprint.co.uk</a></div>
            )}
        </div>
    );
}

export default GetInTouch;
