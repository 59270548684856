import React from 'react';
import { Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Logo from './assets/Global/Wizziprint_Logo.png';

export default () => <header className="App-header">
<Link to="/"><img className='Header-logo' src={Logo} alt="Wizziprint Logo" /></Link>
<div className='Header-menu'>
    <div className="Content-center">
        <Grid container spacing={2}>
            <Grid item xs={4}> 
                <Link to="/">HOME</Link>
            </Grid>
            <Grid item xs={4}> 
                <Link to="/testimonials">TESTIMONIALS</Link>
            </Grid>
            <Grid item xs={4}> 
                <Link to="/get-in-touch">GET IN TOUCH</Link>
            </Grid>
        </Grid>
    </div>
</div>
</header>;